.search {
  margin-bottom: 10px;
  position: relative;
}

.search__input {
  @include search-input();

  display: block;
  padding: 15px;
  padding-right: 30px;
  line-height: 18px;
  font-size: 16px;

  &::placeholder {
    color: lighten($darker-text-color, 4%);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }

  &:focus {
    background: lighten($ui-base-color, 4%);
  }
}

.search__icon {
  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus {
    outline: 0 !important;
  }

  .fa {
    position: absolute;
    top: 16px;
    right: 10px;
    z-index: 2;
    display: inline-block;
    opacity: 0;
    transition: all 100ms linear;
    transition-property: color, transform, opacity;
    font-size: 18px;
    width: 18px;
    height: 18px;
    color: $secondary-text-color;
    cursor: default;
    pointer-events: none;

    &.active {
      pointer-events: auto;
      opacity: 0.3;
    }
  }

  .fa-search {
    transform: rotate(0deg);

    &.active {
      pointer-events: auto;
      opacity: 0.3;
    }
  }

  .fa-times-circle {
    top: 17px;
    transform: rotate(0deg);
    color: $action-button-color;
    cursor: pointer;

    &.active {
      transform: rotate(90deg);
    }

    &:hover {
      color: lighten($action-button-color, 7%);
    }
  }
}

.search-results__header {
  color: $dark-text-color;
  background: lighten($ui-base-color, 2%);
  padding: 15px;
  font-weight: 500;
  font-size: 16px;
  cursor: default;

  .fa {
    display: inline-block;
    margin-right: 5px;
  }
}

.search-results__info {
  padding: 20px;
  color: $darker-text-color;
  text-align: center;
}

.trends {
  &__header {
    color: $dark-text-color;
    background: lighten($ui-base-color, 2%);
    border-bottom: 1px solid darken($ui-base-color, 4%);
    font-weight: 500;
    padding: 15px;
    font-size: 16px;
    cursor: default;

    .fa {
      display: inline-block;
      margin-right: 5px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid lighten($ui-base-color, 8%);
    gap: 15px;

    &:last-child {
      border-bottom: 0;
    }

    &__name {
      flex: 1 1 auto;
      color: $dark-text-color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      strong {
        font-weight: 500;
      }

      a {
        color: $darker-text-color;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover,
        &:focus,
        &:active {
          span {
            text-decoration: underline;
          }
        }
      }
    }

    &__current {
      flex: 0 0 auto;
      font-size: 24px;
      font-weight: 500;
      text-align: right;
      color: $secondary-text-color;
      text-decoration: none;
    }

    &__sparkline {
      flex: 0 0 auto;
      width: 50px;

      path:first-child {
        fill: rgba($highlight-text-color, 0.25) !important;
        fill-opacity: 100% !important;
      }

      path:last-child {
        stroke: lighten($highlight-text-color, 6%) !important;
        fill: none !important;
      }
    }

    &--requires-review {
      .trends__item__name {
        color: $gold-star;

        a {
          color: $gold-star;
        }
      }

      .trends__item__current {
        color: $gold-star;
      }

      .trends__item__sparkline {
        path:first-child {
          fill: rgba($gold-star, 0.25) !important;
        }

        path:last-child {
          stroke: lighten($gold-star, 6%) !important;
        }
      }
    }

    &--disabled {
      .trends__item__name {
        color: lighten($ui-base-color, 12%);

        a {
          color: lighten($ui-base-color, 12%);
        }
      }

      .trends__item__current {
        color: lighten($ui-base-color, 12%);
      }

      .trends__item__sparkline {
        path:first-child {
          fill: rgba(lighten($ui-base-color, 12%), 0.25) !important;
        }

        path:last-child {
          stroke: lighten(lighten($ui-base-color, 12%), 6%) !important;
        }
      }
    }
  }

  &--compact &__item {
    padding: 10px;
  }
}
